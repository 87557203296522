<template>
    <b-container fluid>
        <div class="row align-items-center">

            <div class="col-md-3">
                <div class="form-group">
                    <label for="company">Modus:</label><br>
                    <select class="form-control form-control-sm" @change="changeMode($event)" v-model="finalAbo.mode">
                        <option value="date">Datum</option>
                        <option value="day">Wochentag</option>
                    </select>
                </div> 

                <!-- <div class="form-group" v-if="finalAbo.mode == 'day'">
                    <label for="company">Wochentag:</label><br>
                    <select class="form-control form-control-sm" @change="changeDay($event)" v-model="finalAbo.day">
                        <option value="1">Montag</option>
                        <option value="2">Dienstag</option>
                        <option value="3">Mittwoch</option>
                        <option value="4">Donnerstag</option>
                        <option value="5">Freitag</option>
                        <option value="6">Samstag</option>
                    </select>
                </div>  -->
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="company">Interval:</label><br>
                    <select class="form-control form-control-sm" v-model="finalAbo.interval">
                        <option value="week">Wöchentlich</option>
                        <option value="two_week">Zweiwöchentlich</option>
                        <option value="month">Monatlich</option>
                        <option value="quarter">Quartal</option>
                        <option value="half_year">Halbjährlich</option>
                        <option value="year">Jährlich</option>
                    </select>
                </div> 
            </div>

            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="company">Nächste Ausführung:</label><br>
                            <input type="date" class="form-control form-control-sm" v-model="finalAbo.next_date" v-if="finalAbo.mode == 'date'"/>

                            <input type="date" class="form-control form-control-sm" v-model="finalAbo.next_date" v-if="finalAbo.mode == 'day'">

                            <!-- <select class="form-control form-control-sm" v-if="finalAbo.mode == 'day'" v-model="finalAbo.next_date">
                                <option v-for="date in finalAbo.available_dates" :key="date" :value="date">{{date | formatDate }}</option>
                            </select> -->
                        </div> 
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="company">Übernächste Ausführung:</label><br>
                            <input disabled type="date" class="form-control form-control-sm" v-model="finalAbo.overnext_date"/>
                        </div> 
                    </div>
                </div>
                
            </div>

            <div class="col-md-3">
                <div class="row align-items-center">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="company">Aktiv:</label><br>
                            <select class="form-control form-control-sm" v-model="finalAbo.active">
                                <option value="0">Nein</option>
                                <option value="1">Ja</option>
                            </select>
                        </div> 
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="company">Aussetzen:</label><br>
                            <button type="button" class="btn btn-primary btn-sm" @click="showSuspendModal">Konfigurieren</button>
                        </div> 
                    </div>

                    <div class="col-md-4">
                        <div class="form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchService" v-model="params.service">
                                <label class="custom-control-label" for="customSwitchService">Auto Status?</label>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>            

        </div>

        <div class="row mb-2" v-if="abo.suspend.length > 0">
            <div class="col-md-12">
                <strong>Abo setzt an folgenden Tagen aus: </strong><br>
                <!-- <div v-for="suspend in abo.suspend" :key="suspend"> -->
                    <span v-for="suspend in abo.suspend" :key="suspend" class="badge badge-pill badge-warning mr-1" >{{ suspend | formatDate }}</span>
                <!-- </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rechnungsadresse</h3>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.billing_id">
                                <option v-for="address in params.customer.addresses" :key="address.id" :value="address.id">
                                    {{ address.first_name }} {{ address.last_name }} /
                                    <template v-if="address.type == 'address'">
                                    {{ address.street }} {{ address.house_number }} /
                                    </template>
                                    <template v-if="address.type == 'packstation'">
                                    {{ address.post_customer_number }} / Packstation {{ address.post_number }} /
                                    </template>
                                    <template v-if="address.type == 'postfiliale'">
                                    {{ address.post_customer_number }} / Postfiliale {{ address.post_number }} /
                                    </template>
                                    {{ address.zipcode }} {{ address.city }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Lieferadresse</h3>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Rechnungsanschrift verwenden?</label>
                            <select class="form-control form-control-sm" v-model="params.useBillingAddress">
                                <option value="true">Ja</option>
                                <option value="false">Nein</option>
                            </select>
                        </div>

                        <div class="form-group" v-if="params.useBillingAddress == 'false'">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.shipping_id">
                                <option v-for="address in params.customer.addresses" :key="address.id" :value="address.id">
                                    {{ address.first_name }} {{ address.last_name }} /
                                     <template v-if="address.type == 'address'">
                                    {{ address.street }} {{ address.house_number }} /
                                    </template>
                                    <template v-if="address.type == 'packstation'">
                                    {{ address.post_customer_number }} / Packstation {{ address.post_number }} /
                                    </template>
                                    <template v-if="address.type == 'postfiliale'">
                                    {{ address.post_customer_number }} / Postfiliale {{ address.post_number }} /
                                    </template>
                                    {{ address.zipcode }} {{ address.city }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Zahlungsart</h3>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.payment_id">
                                <option v-for="payment in payments" :key="payment.id" :value="payment.id">
                                    {{ payment.description }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Bestellinformationen</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <span>Zwischensumme (Positionen): {{ order.zwischensumme | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span v-if="order.total_discount > 0" style="color: red">abzgl. Rabatt: -{{ order.total_discount | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span v-if="order.total_pledge > 0">zzgl. Pfand: {{ order.total_pledge | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span v-if="order.total_cool > 0">zzgl. Kühlversand: {{ order.total_cool | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span v-if="order.total_fragile > 0">zzgl. Bruchzuschlag: {{ order.total_fragile | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span v-if="order.total_cargo > 0">zzgl. Sperrgutzuschlag: {{ order.total_cargo | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span>zzgl. Versandkosten: {{ order.total_shipping | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="mt-3 row">
                                    <div class="col-md-12">
                                        <hr>
                                        <span>Gesamtsumme (Netto): {{ order.total_netto | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-for="tax in order.tax" :key="tax.type">
                                    <div class="col-md-12">
                                        <span><em>zzgl. {{ parseFloat(tax.type) }} % MwSt: {{ tax.amount | toCurrency }}</em></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span><strong>Gesamtsumme (Brutto): {{ order.total_brutto | toCurrency }}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Versandkosten</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Wähle</label>
                                    <select class="form-control form-control-sm" v-model="params.dispatch_id">
                                        <option v-for="dispatch in filterDispatches()" :key="dispatch.id" :value="dispatch.id">
                                            {{ dispatch.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Versandkosten</label>
                                    <input type="number" v-model="params.shippingCost"  class="form-control form-control-sm" />
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchShipping" v-model="params.shippingFree">
                                        <label class="custom-control-label" for="customSwitchShipping">Versandkostenfrei?</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="findDispatch() == true">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="store">Filiale</label>
                                    <select class="form-control form-control-sm" v-model="params.store_id">
                                        <option value="">-- Wähle Filiale --</option>
                                        <option v-for="store in stores" :key="store.id" :value="store.id">{{ store.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rabatte</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label>Rabatt(%)</label>
                            <input type="number" class="form-control form-control-sm" v-model="order.discount" />
                        </div>

                        <div class="form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchDiscount" v-model="params.useDiscount">
                                <label class="custom-control-label" for="customSwitchDiscount">Rabatt anwenden?</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Kommentar</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label>Intern</label>
                            <textarea class="form-control" rows="4" v-model="order.internalcomment"></textarea>
                            <label class="mt-2">Extern</label>
                            <textarea class="form-control" rows="4" v-model="order.externalcomment"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;" v-if="findDispatch() == false">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Lieferoptionen</h3>
                    </div>
                    <div class="card-body">
                        <!-- <div class="form-group">
                            <label>Abstellhinweis</label>
                            <br>
                            <select v-model="order.delivery_note" class="form-control form-control-sm">
                                <option value="none">
                                    Kein Abstellhinweis
                                </option>
                                <option v-for="note in notes" :key="note.key" :value="note.key">
                                    {{ note.title }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group" v-if="order.delivery_note == 'andere'">
                            <input v-model="order.delivery_note_additional" type="text" class="form-control form-control-sm" placeholder="...mein Wunschort">   
                        </div> -->
                        
                        
                        <label>Abstellhinweis</label>
                            <br>
                            <div class="form-group">
                                <input type="radio" v-model="order.delivery_note" value="none" class="form-check-inline form-check">
                                <label  class="control-label form-check-label" >Kein Abstellhinweis</label>
                            </div>
                            <div v-for="note in notes" :key="note.key" class="form-check-inline form-check form-group">
                                <input type="radio" v-model="order.delivery_note"  :value="note.key" class="form-check-input">
                                <label class="control-label form-check-label">{{ note.title }}</label>
                            </div>
                            <div class="form-group" v-if="order.delivery_note == 'andere'">
                                <input v-model="order.delivery_note_additional" type="text" class="form-control form-control-sm" placeholder="...mein Wunschort">   
                            </div>

                        <div class="form-group">
                            <label>Alternativartikel</label>
                            <div class="ml-2 form-check">
                                <input v-model="order.alternative" class="form-check-input" id="alternativartikel" type="checkbox" name="alternative" value="1" />

                                <label class="form-check-label" for="alternativartikel">Ja, der Kunde ist bei Bedarf mit einem Ersatzartikel einverstanden</label>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label for="wishdate">Wunsch-Lieferdatum:</label>           
                            <div class="ml-2 form-check">

                                <input v-model="order.wishdate" class="form-check-input form-control form-control-sm" id="wishdate" type="date" name="wishdate">
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Kundeninformation</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label>Interner Kundenkommentar:</label><br>
                            <span v-if="params.customer.comment != null" v-html="params.customer.comment"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Positionen</h3>

                        <div class="card-tools" >

                            <div class="mr-1 form-group" style="display: inline-block;">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchCool" v-model="params.useCool">
                                    <label class="custom-control-label" for="customSwitchCool">Kühlversand</label>
                                </div>
                            </div>

                            <div class="mr-1 form-group" style="display: inline-block;">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchFragile" v-model="params.useFragile">
                                    <label class="custom-control-label" for="customSwitchFragile">Bruchzuschlag</label>
                                </div>
                            </div>

                            <div class="mr-1 form-group" style="display: inline-block;">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchCargo" v-model="params.useCargo">
                                    <label class="custom-control-label" for="customSwitchCargo">Sperrgut</label>
                                </div>
                            </div>


                            <!-- <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addDepositReturn"><i class="fas fa-plus"></i> Pfandrückgabe</button> -->
                            <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                            <button class="mr-1 btn btn-secondary" @click="addDeposit"><i class="fas fa-plus"></i> Pfandverkauf</button>
                            <button class="btn btn-success" @click="showArticleModal"><i class="fas fa-plus"></i> Hinzufügen</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                                <th style="width:10%;">Artikelnummer</th>
                                <th style="width: 54%;">Name</th>
                                <th style="width: 6%;">Rabattfähig</th>
                                <th style="width: 6%;">Anzahl</th>
                                <th style="width: 10%;">Preis</th>
                                <th>Total</th>
                                <th>Steuersatz</th>
                                <th>Aktion</th>
                            </thead>

                            <tbody>
                                <tr v-for="(article, index) in params.articles" :key="article.id">
                                    <td>
                                        <input v-if="article.type == 'article_free'" type="text" v-model="params.articles[index].articleordernumber" class="form-control form-control-sm" />
                                        <span v-else>{{ article.articleordernumber }}</span>
                                    </td>
                                    <td>
                                        <input v-if="article.type == 'article_free' || article.type == 'deposit_sale'" type="text" v-model="params.articles[index].name" class="form-control form-control-sm" />
                                        <span v-else>{{ article.name }}
                                            <br>
                                            <span v-if="article.deposit > 0"><small>Pfandartikel</small></span>
                                        </span>
                                    </td>
                                    <td>
                                        <!-- <div class="mr-1 form-group" style="display: inline-block;"> -->
                                            <div class="custom-control custom-switch" :class="article.type != 'deposit_sale' ? 'custom-switch-off-danger custom-switch-on-success' : ''">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitchDiscountable' + index" v-model="params.articles[index].discountable" :disabled="article.type == 'deposit_sale'">
                                                <label class="custom-control-label" :for="'customSwitchDiscountable' + index"></label>
                                            </div>
                                        <!-- </div> -->
                                    </td>
                                    <td><input type="number" class="form-control form-control-sm" v-model="params.articles[index].quantity" oninput="validity.valid||(value='');" min="0" step="1" /></td>
                                    <td><input type="number" class="form-control form-control-sm" v-model="params.articles[index].price" min="0" step="0.01" oninput="validity.valid||(value='');" /></td>
                                    <td>{{ article.price * article.quantity  | toCurrency }}</td>
                                    <td>
                                        <select v-if="article.type == 'article_free' || article.type=='deposit_sale'" v-model="params.articles[index].tax_rate" class="form-control form-control-sm" >
                                            <option v-for="tax in taxes" :key="tax.id" :value="tax.tax">{{tax.description}}</option>
                                        </select>
                                        <span v-else>{{ article.tax_rate }}</span>
                                        
                                    </td>
                                    <td>
                                        <button class="btn btn-danger" @click="removeItem(index)"><i class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>              
                    </div>  

                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-7">

                            </div>
                            <div class="text-right col-md-5">
                                <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                                <button class="mr-1 btn btn-secondary" @click="addDeposit"><i class="fas fa-plus"></i> Pfandverkauf</button>
                                <button class="btn btn-success mr-1" @click="showArticleModal"><i class="fas fa-plus"></i> Hinzufügen</button>
                                <button class="btn btn-danger" @click="saveOrder" :class="{disabled: !notEmptyObject(this.params.customer) || this.params.articles.length <= 0 || this.params.payment_id == ''}"><i class="fas fa-save"></i> Abo speichern</button>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

    <b-modal id="suspendModal" title="Aussetzen wählen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="saveSuspend">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Wähle die Daten an denen das Abo ausgesetzt werden soll</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2" v-for="date in available_dates" :key="date">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :value="date" v-model="finalAbo.suspended_dates">
                                    <label class="form-check-label">{{  date | formatDate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>


        <ArticleModal />

        <b-modal id="customerModal" title="Kunde wählen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Versanddaten</h3>
                <div class="card-tools">
                   
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getCustomers" v-model="search" />
                        <button type="submit" class="btn btn-default" @click.prevent="getCustomers">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                                    
                                
                </div>
              </div>
              <div class="card-body">
                <table class="table table-striped">
                                    <thead>
                                        <tr data-widget="expandable-table" aria-expanded="false">
                                            <th>
                                                Kundennummer
                                            </th>
                                            <th>
                                                E-Mail
                                            </th>
                                            <th>
                                                Firma
                                            </th>
                                            <th>
                                                Vorname
                                            </th>
                                            <th>
                                                Nachname
                                            </th>
                                            <th>
                                                PLZ
                                            </th>
                                            <th>
                                                Stadt
                                            </th>
                                            <th>Aktionen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="customer in customers.data" :key="customer.id">
                                            <td>{{ customer.customernumber}}</td>
                                            <td>{{ customer.email}}</td>
                                            <td>{{ customer.billing_address.company }}</td>
                                            <td>{{ customer.first_name }}</td>
                                            <td>{{ customer.last_name }}</td>
                                            <td>{{ customer.billing_address.zipcode }}</td>
                                            <td>{{ customer.billing_address.city }}</td>
                                            <td>
                                                <b-button size="xs" @click="setCustomer(customer)" variant="primary"><i class="fas fa-user"></i> Auswählen</b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
              </div>
              <div class="card-footer">
                        <pagination class="float-left" :data="customers" @pagination-change-page="getCustomers" :limit="3"></pagination>
                        <p v-if="notEmptyObject(customers)" class="float-right mr-2">Anzeige Eintrag {{ customers.meta.from }} - {{ customers.meta.to }} von {{ customers.meta.total }}</p>
                    </div>
            </div>
          </div>
        </div>

        
    </b-modal>
    </b-container>
</template>

<script>
import ArticleModal from './components/AddModal';
import moment from 'moment'

export default {
    components: {
      ArticleModal,
  },

    data() {
        return {
            abo: {
                suspend: []
            },
            available_dates: [],
            finalAbo: {
                interval: 'week',
                mode: 'date',
                start_date: null,
                next_date: null,
                overnext_date: null,
                day: '1',
                active: '',
                available_dates: [],
                suspended_dates: [],
            },
            notes: [
                {
                    key: 'garage', 
                    title: 'Garage', 
                }, 
                {
                    key: 'terrasse', 
                    title: 'Terrasse', 
                }, 
                {
                    key: 'haustuer', 
                    title: 'Haustuer', 
                }, 
                {
                    key: 'kellertreppe', 
                    title: 'Kellertreppe', 
                }, 
                {
                    key: 'andere', 
                    title: 'Andere', 
                }, 
            ],
            stores: [], 
            companies: [],
            customers: {},
            payments: [],
            dispatches: [],
            articles: [],
            taxes: [],
            selectedRow: {},
            params: {
                company_id: '',
                customer: '',
                useBillingAddress: "true",
                billing_id: '',
                shipping_id: '',
                shipping_address: {},
                payment_id: '',
                dispatch_id: '',
                articles: [],
                useCool: true,
                useCargo: true,
                useFragile: true,
                useDiscount: true,
                shippingFree: false,
                shippingCost: 7.90,
                store_id: '', 
                service: false,
            },

            order: {
                total_brutto: 0,
                total_netto: 0,
                total_pledge: 0,
                total_cool: 0,
                total_fragile: 0,
                total_cargo: 0,
                total_shipping: 0,
                total_shipping_net: 0,
                total_shipping_tax: '7.00',
                total_discount: 0,
                zwischensumme: 0,
                deposit: [],
                tax: [],
                heavy: [],
                fragile: [], 
                customercomment: '',
                internalcomment: '',
                externalcomment: '',
                discount: 0,
                alternative: false, 
                wishdate: '', 
                delivery_note: 'none', 
                delivery_note_additional: '', 

            },

            finalOrder: {},
            
            search: '',
        }
    },

    watch: {
        'order.discount': function() {
            this.generateTotal();
        },

        'params.useDiscount': function() {
            this.generateTotal();
        },

        'params.useCool': function() {
            this.generateTotal();
        },

        'params.shippingFree': function() {
            this.generateTotal();
        },

        'params.shippingCost': function() {
            this.generateTotal();
        },

        'params.useFragile': function() {
            this.generateTotal();
        },

        'params.useCargo': function() {
            this.generateTotal();
        },

        'params.company_id': function() {
            this.params.customer_id =  '';
            this.params.dispatch_id = null;
        },

        'params.useBillingAddress': function() {
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }

            //Prüfe ob innerhalb
            if(this.params.shipping_address.zipcode >= 57000 && this.params.shipping_address.zipcode <= 59999)
            {
                //Innerhalb
                this.params.dispatch_id = 1
            }

            else
            {
                //Außerhalb
                if(this.hasCool() == true)
                {
                    this.params.dispatch_id = 4;
                }

                else{
                    this.params.dispatch_id = 3;
                }
            }
            this.generateTotal();
        },

        'params.shipping_id': function() {
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }

            //Prüfe ob innerhalb
            if(this.params.shipping_address.zipcode >= 57000 && this.params.shipping_address.zipcode <= 59999)
            {
                //Innerhalb
                this.params.dispatch_id = 1
            }

            else
            {
                //Außerhalb
                if(this.hasCool() == true)
                {
                    this.params.dispatch_id = 4;
                }

                else{
                    this.params.dispatch_id = 3;
                }
            }
            this.generateTotal();
        },

        'params.customer': function() {
            // this.params.shipping_id = this.params.customer.default_shipping_address_id;
            // this.params.billing_id = this.params.customer.default_billing_address_id;

            //Prüfe ob Kunde einen Kundenrabatt oder Gruppenrabatt hat
            if(this.order.discount <= 0)
            {
                if(this.params.customer.discount > 0)
                {
                    //Es gibt auf jeden Fall einen Kunden Rabatt
                   
                    this.order.discount = this.params.customer.discount;
                }
                else{
                    //Es gibt keinen Kundenrabatt. Prüfe ob es einen Kundengruppenrabatt gibt
                    if(this.params.customer.group.discount > 0)
                    {
                        //Kundengruppenrabatt
                        this.order.discount = this.params.customer.group.discount;
                    }
                    else{
                        this.order.discount = 0;
                    }
                }
            }

            //var address = null;
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }

            //Prüfe ob innerhalb
            if(this.params.shipping_address.zipcode >= 57000 && this.params.shipping_address.zipcode <= 59999)
            {
                //Innerhalb
                this.params.dispatch_id = 1
            }

            else
            {
                //Außerhalb
                if(this.hasCool() == true)
                {
                    this.params.dispatch_id = 4;
                }

                else{
                    this.params.dispatch_id = 3;
                }
            }
            this.generateTotal();
            
        },

        'params.articles': {
            handler() {
                if(this.params.shipping_address.zipcode >= 57000 && this.params.shipping_address.zipcode <= 59999)
                {
                    //Innerhalb
                    this.params.dispatch_id = 1
                }

                else
                {
                    //Außerhalb
                    if(this.hasCool() == true)
                    {
                        this.params.dispatch_id = 4;
                    }

                    else{
                        this.params.dispatch_id = 3;
                    }
                }
                this.generateTotal();
                //console.log(this.hasCool());
            },
            deep: true,
        },

        'finalAbo.mode': {
            handler(newValue) {
                this.generateNextDate();
                if(newValue == 'day')
                {
                    this.changeDay();
                }
            },
        },

        'finalAbo.interval': {
            handler() {
                this.generateNextDate();
                this.changeDay();
            },
        },

        'finalAbo.next_date': {
            handler() {
                this.generateNextDate();
            },
        },
    },

    methods: {
        filterDispatches() {
            return this.dispatches.filter(el => {
                return el.company_id == this.params.company_id;
            })
        },
        showSuspendModal(){
            this.generateDates();
            this.$bvModal.show("suspendModal");
        },

        saveSuspend(bvModalEvt){
            bvModalEvt.preventDefault()
            this.axios
                .post("/abos/suspend", {
                    'abo_id': this.abo.id,
                    'suspended_dates': this.finalAbo.suspended_dates,
                })
                .then(() => {
                    this.$bvModal.hide("suspendModal");
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.getAbo();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        generateNextDate(){
            var date = null;
            
            if(this.finalAbo.mode == 'day')
            {
                date = moment(this.finalAbo.next_date);

                if(this.finalAbo.interval == 'week')
                {
                    //Intervall ist wöchentlich
                    date.add(1, 'weeks');
                }

                if(this.finalAbo.interval == 'two_week')
                {
                    //Intervall ist wöchentlich
                    date.add(2, 'weeks');
                }

                if(this.finalAbo.interval == 'month')
                {
                    //Intervall ist wöchentlich
                    date.add(4, 'weeks');
                }

                if(this.finalAbo.interval == 'quarter')
                {
                    //Intervall ist wöchentlich
                    date.add(12, 'weeks');
                }

                if(this.finalAbo.interval == 'half_year')
                {
                    //Intervall ist wöchentlich
                    date.add(24, 'weeks');
                }

                if(this.finalAbo.interval == 'year')
                {
                    date.add(1, 'years');
                    //Intervall ist Jährlich
                    // var sunday = new Date(parseInt(date.format('Y')) + 1, 0, (1 + (parseInt(date.format('W')) - 1) * 7));
                    // while (sunday.getDay() !== parseInt(this.abo.day) + 1) {
                    //     sunday.setDate(sunday.getDate() - 1);
                    // }
                    // date = moment(sunday.toISOString().slice(0, 10));
                }
            }

            if(this.finalAbo.mode == 'date')
            {
                date = moment(this.finalAbo.next_date);

                if(this.finalAbo.interval == 'week')
                {
                    //Intervall ist wöchentlich
                    date.add(1, 'weeks');
                }

                if(this.finalAbo.interval == 'two_week')
                {
                    //Intervall ist wöchentlich
                    date.add(2, 'weeks');
                }

                if(this.finalAbo.interval == 'month')
                {
                    //Intervall ist wöchentlich
                    date.add(1, 'months');
                }

                if(this.finalAbo.interval == 'quarter')
                {
                    //Intervall ist wöchentlich
                    date.add(3, 'months');
                }

                if(this.finalAbo.interval == 'half_year')
                {
                    //Intervall ist wöchentlich
                    date.add(6, 'months');
                }

                if(this.finalAbo.interval == 'year')
                {
                    //Intervall ist Jährlich
                    date.add(1, 'years');
                }
            }

            this.finalAbo.overnext_date = date.format('Y-MM-DD');


        },

        generateDates(){
            var date = null;
            date = moment(this.finalAbo.next_date);
            
            // this.abo.next_date = date.format('Y-MM-DD');
            this.available_dates = [];
            this.available_dates.push(date.format('Y-MM-DD'));

            //Generiere die nächsten 10 Intervalle
            for(var i = 1; i < 60; i++){
                    if(this.finalAbo.interval == 'week')
                    {
                        //Intervall ist wöchentlich
                        date.add(1, 'weeks');
                    }

                    if(this.finalAbo.interval == 'two_week')
                    {
                        //Intervall ist wöchentlich
                        date.add(2, 'weeks');
                    }

                    if(this.finalAbo.interval == 'month')
                    {
                        //Intervall ist wöchentlich
                        date.add(4, 'weeks');
                    }

                    if(this.finalAbo.interval == 'quarter')
                    {
                        //Intervall ist wöchentlich
                        date.add(12, 'weeks');
                    }

                    if(this.finalAbo.interval == 'half_year')
                    {
                        //Intervall ist wöchentlich
                        date.add(24, 'weeks');
                    }

                    if(this.finalAbo.interval == 'year')
                    {
                        date.add(1, 'years');
                    //Intervall ist Jährlich
                    // var sunday = new Date(parseInt(date.format('Y')) + 1, 0, (1 + (parseInt(date.format('W')) - 1) * 7));
                    // while (sunday.getDay() !== parseInt(this.abo.day) + 1) {
                    //     sunday.setDate(sunday.getDate() - 1);
                    // }
                    // date = moment(sunday.toISOString().slice(0, 10));
                    }

                    this.available_dates.push(date.format('Y-MM-DD'));
            }
        },

        changeDay(){
    
    
            var dayOfWeek = parseInt(this.finalAbo.day);//friday
            var now = moment();

            const today = moment().isoWeekday();

            var date = null;

            if (today < dayOfWeek) { 
                date = moment().isoWeekday(dayOfWeek);
            }
            else
            {
                date = moment().add(1, 'weeks').isoWeekday(dayOfWeek);
            }

            if(date.diff(now, 'days') < 4)
            {
                date.add(1, 'weeks');
            }
            

            // this.abo.next_date = date.format('Y-MM-DD');
            this.finalAbo.available_dates = [];
            this.finalAbo.available_dates.push(date.format('Y-MM-DD'));

            //Generiere die nächsten 10 Intervalle
            for(var i = 1; i < 10; i++){
                if(this.finalAbo.interval == 'week')
                {
                    //Intervall ist wöchentlich
                    date.add(1, 'weeks');
                }

                if(this.finalAbo.interval == 'two_week')
                {
                    //Intervall ist wöchentlich
                    date.add(2, 'weeks');
                }

                if(this.finalAbo.interval == 'month')
                {
                    //Intervall ist wöchentlich
                    date.add(4, 'weeks');
                }

                if(this.finalAbo.interval == 'quarter')
                {
                    //Intervall ist wöchentlich
                    date.add(12, 'weeks');
                }

                if(this.finalAbo.interval == 'half_year')
                {
                    //Intervall ist wöchentlich
                    date.add(24, 'weeks');
                }

                if(this.finalAbo.interval == 'year')
                {
                    date.add(1, 'years');
                    //Intervall ist Jährlich
                    // var sunday = new Date(parseInt(date.format('Y')) + 1, 0, (1 + (parseInt(date.format('W')) - 1) * 7));
                    // while (sunday.getDay() !== parseInt(this.abo.day) + 1) {
                    //     sunday.setDate(sunday.getDate() - 1);
                    // }
                    // date = moment(sunday.toISOString().slice(0, 10));
                }

                this.finalAbo.available_dates.push(date.format('Y-MM-DD'));
            }


            // //Prüfe ob es das aktuelle Next Date in den verfügbaren Daten gibt
            // if(this.finalAbo.available_dates.find(item => item == this.finalAbo.next_date) == undefined)
            // {
            //     //Konnte das Datum nicht finden. Setzte auf erstes verfügbares Datum
            //     this.finalAbo.next_date = this.finalAbo.available_dates[0];
            // }
            
        },

        changeMode(event){
            
            if(event.target.value == 'date'){
                // Es wurde der Modus Date gewählt
            }

            if(event.target.value == 'day'){
                // Es wurde der Modus Tag gewählt
            }
        },

        findDispatch()
        {
            var dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id); 

            if(dispatch == undefined) 
            {
                return false;  
            }
            else if(dispatch.name == 'Selbstabholung')
            {
                return true; 
            }
            else
            {
                return false; 
            }
        }, 

        setCustomer(customer){
            this.params.customer = customer;
            this.$bvModal.hide('customerModal');
        },

        selectCustomer()
        {
            this.getCustomers();
            this.$bvModal.show('customerModal');
        },


        saveOrder()
        {
            this.finalOrder.customer_id = this.params.customer.id;
            this.finalOrder.company_id = this.params.company_id;
            this.finalOrder.dispatch_id = this.params.dispatch_id;
            this.finalOrder.payment_id = this.params.payment_id;
            this.finalOrder.internalcomment = this.order.internalcomment;
            this.finalOrder.externalcomment = this.order.externalcomment;
            this.finalOrder.invoice_amount = this.order.total_brutto;
            this.finalOrder.billing_address_id = this.params.billing_id;
            this.finalOrder.shipping_address_id = this.params.shipping_id;
            this.finalOrder.shippingFree = this.params.shippingFree;
            this.finalOrder.shippingCost = this.params.shippingCost;
            this.finalOrder.discount = this.order.discount;
            this.finalOrder.useDiscount = this.params.useDiscount;
            this.finalOrder.useFragile = this.params.useFragile;
            this.finalOrder.useCargo = this.params.useCargo;
            this.finalOrder.useCool = this.params.useCool;
            this.finalOrder.alternative = this.order.alternative;
            this.finalOrder.wishdate = this.order.wishdate;
            this.finalOrder.store_id = this.params.store_id;
            this.finalOrder.delivery_note = this.order.delivery_note;
            this.finalOrder.delivery_note_additional = this.order.delivery_note_additional;
            this.finalOrder.start_date = this.finalAbo.start_date;
            this.finalOrder.next_date = this.finalAbo.next_date;
            this.finalOrder.mode = this.finalAbo.mode;
            this.finalOrder.interval = this.finalAbo.interval;
            this.finalOrder.day = this.finalAbo.day;
            this.finalOrder.active = this.finalAbo.active;
            this.finalOrder.service = this.params.service;
            this.finalOrder.items = [];

            var data;
            this.params.articles.forEach(function(item) {
                //Füge jeden Artikel hinzu :-)
                data = {};
                
                data.article_id = item.article_id;
                data.article_detail_id = item.article_detail_id;
                data.articleordernumber = item.articleordernumber;
                data.cool = item.cool;
                data.deposit = item.deposit;
                data.deposit_type = item.deposit_type;
                data.fragile = item.fragile;
                data.heavy = item.heavy;
                data.name = item.name;
                data.price = item.price;
                data.quantity = item.quantity;
                data.tax_rate = item.tax_rate;
                data.type = item.type;
                data.discountable = item.discountable;
                this.finalOrder.items.push(data);
            },this);

            //Schreibe die Daten in die Datenbank
            this.$swal({
                    title: "Möchtest du die Bestellung wirklich speichern?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, speichern!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .put("/abos/" + this.$route.params.id, this.finalOrder)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Abo erstellt",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                // this.$router.push({name: 'abos.index'});
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });

        },

        removeItem(index)
        {
            this.$delete(this.params.articles, index);
        },

        hasCool(){
            var result = false;
            this.params.articles.forEach(function(item) {
                if(item.cool == 1)
                {
                    result = true;
                }
            },result);
            return result;
        },

        addFreeArticle(){
            this.params.articles.push({
                'articleordernumber': 'FA',
                'name': 'Freier Artikel',
                'quantity': 1,
                'price': 1,
                'tax_rate': '7.00',
                'article_id': 0,
                'article_detail_id': 0,
                'type': 'article_free',
                'deposit': 0,
                'deposit_type': 'Mehrweg-Pfand',
                'cool': 0,
                'fragile': 0,
                'heavy': 0,
                'discountable': 0,
            });
        },

        addDeposit(){
            this.params.articles.push({
                'articleordernumber': 'Pfandverkauf',
                'name': 'Pfandverkauf',
                'quantity': 1,
                'price': 1,
                'tax_rate': '19.00',
                'article_id': 0,
                'article_detail_id': 0,
                'type': 'deposit_sale',
                'deposit': 0,
                'deposit_type': 'Mehrweg-Pfand',
                'cool': 0,
                'fragile': 0,
                'heavy': 0,
                'discountable': 0
            });
        },

        addDepositReturn(){

        },

        generateTotal(){
            this.order.deposit = [];
            this.order.tax = [];
            this.order.heavy = [];
            this.order.fragile = [];
            this.order.total_brutto = 0;
            this.order.total_netto = 0;
            this.order.total_pledge = 0;
            this.order.total_cargo = 0;
            this.order.total_cool = 0;
            this.order.total_fragile = 0;
            this.order.total_discount = 0;
            this.order.zwischensumme = 0;
            var cool_value = 0;
            var cool_tax = 0;
            
            this.params.articles.forEach(function(item) {
                var deposit_value = 0;
                var deposit_tax = 0;
                var heavy_value = 0;
                var heavy_tax = 0;
                var fragile_value = 0;
                var fragile_tax = 0;

                // 1) Prüfe ob dies überhaupt ein Artikel ist
                if(item.type == 'article' || item.type == 'article_free'){

                    //################Bruchgut Prüfung###################
                    //Prüfe ob der Artikel Sperrgutzuschlag hat
                    if(item.fragile == 1)
                    {
                        //Artikel hat Sperrgut. Prüfe ob Sperrgut berechnet wird
                        var fragile = 0;
                        if(parseInt(this.params.shipping_address.zipcode) < 57000 || parseInt(this.params.shipping_address.zipcode) > 59999)
                        {
                            fragile = 1;
                        }

                        if(fragile == 1 && this.params.useFragile == true)
                        {
                            //Berechne Sperrgut.
                            var fragile_amount = this.order.fragile.find(i => i.type == 'Bruchgut_' + item.tax_rate);
                            if(fragile_amount == undefined)
                            {
                                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var fragile_entry = {
                                    type: 'Bruchgut_'+item.tax_rate,
                                    amount: 4 * item.quantity, //((6 / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity,
                                    tax: item.tax_rate,
                                };

                                this.order.fragile.push(fragile_entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                fragile_amount.amount += (4 * item.quantity);
                            }

                            fragile_value = ((4 * item.quantity) / (100 + parseFloat(item.tax_rate))) * 100;
                            fragile_tax = ((4 * item.quantity) / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate);

                        }

                    }
                    //################Ende Bruchgut#####################

                    //################Sperrgut Prüfung###################
                    //Prüfe ob der Artikel Sperrgutzuschlag hat
                    if(item.heavy == 1)
                    {
                        //Artikel hat Sperrgut. Prüfe ob Sperrgut berechnet wird
                        var cargo = 0;
                        if(parseInt(this.params.shipping_address.zipcode) < 57000 || parseInt(this.params.shipping_address.zipcode) > 59999)
                        {
                            cargo = 1;
                        }

                        if(cargo == 1 && this.params.useCargo == true)
                        {
                            //Berechne Sperrgut.
                            var heavy = this.order.heavy.find(i => i.type == 'Sperrgut_' + item.tax_rate);
                            if(heavy == undefined)
                            {
                                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var heavy_entry = {
                                    type: 'Sperrgut_'+item.tax_rate,
                                    amount: 6 * item.quantity, //((6 / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity,
                                    tax: item.tax_rate,
                                };

                                this.order.heavy.push(heavy_entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                heavy.amount += (6 * item.quantity);
                            }

                            heavy_value = ((6 * item.quantity) / (100 + parseFloat(item.tax_rate))) * 100;
                            heavy_tax = ((6 * item.quantity) / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate);

                        }

                    }
                    //################Ende Sperrgut#####################

                    //Prüfe ob dieser Artikel Pfand hat. Wenn ja muss Pfand berechnet werden
                    if(item.deposit > 0)
                    {
                        //Artikel hat Pfand
                        var string = item.deposit_type + '_' + item.tax_rate;

                        //Prüfe ob es schon ein Pfand Eintrag gibt hierzu.
                        var deposit = this.order.deposit.find(i => i.type == string);
                        if(deposit == undefined)
                        {
                            //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                            var entry = {
                                type: string,
                                amount: item.deposit * item.quantity,
                                tax: item.tax_rate,
                            };

                            this.order.deposit.push(entry);
                        }
                        else
                        {
                            //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                            deposit.amount += (item.deposit * item.quantity);
                        }

                        // Der Artikel hat Pfand. Der Pfandpreis muss also addiert werden.
                        // Prüfe ob der Kunde ein Firmenkunde oder Privatkunde ist.
                        

                        if(this.params.customer == "" || this.params.customer.group.tax == 1)
                        {
                            //Es ist ein Privatkunde
                            //Bei Privatkunden ist der Pfand inkl. MwSt. Also muss der Pfand umgerechnet werden nach Netto
                            deposit_value = ((item.deposit * item.quantity) / (100 + parseFloat(item.tax_rate))) * 100;
                            deposit_tax = ((item.deposit * item.quantity) / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate);
                        }

                        else
                        {
                            //Es ist ein Firmenkunde
                            //Bei Firmenkunden ist der Pfand zzgl Mwst. Pfand ist = Netto. Es muss nur dir anfallende Steuer berechnet werden
                            deposit_value = (item.deposit * item.quantity);
                            deposit_tax = ((item.deposit * item.quantity) / 100) * parseFloat(item.tax_rate);
                        }

                    }

                    //this.order.total_brutto += (item.quantity * item.price);
                    var netto_value = (((item.price / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity);
                    var tax_value = (((item.price / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate)) * item.quantity);

                    var new_brutto = 0;
                    var new_netto = 0;
                    var new_tax = 0;
                    var new_discount = 0;
                    var new_discount_tax = 0;

                    //Prüfe ob der Artikel reduziert wird.
                    if(this.order.discount > 0 && this.params.useDiscount == true && item.discountable == true)
                    {
                        //Es gibt einen Rabatt und dieser soll auch benutzt werden.
                        //Prüfe ob es ein Firmen oder Privatkunde ist

                        if(this.params.customer == "" || this.params.customer.group.tax == 1)
                        {
                            //Es ist ein Privatkunde
                            //1) Ziehe 20% vom Brutto ab
                            new_brutto = (item.price / 100) * (100 - parseFloat(this.order.discount));
                            
                            new_netto = (((new_brutto / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity);
                            new_tax = (((new_netto / (100)) * parseFloat(item.tax_rate)));
                            new_discount = netto_value - new_netto;
                            new_discount_tax = tax_value - new_tax;
                            // console.log('newBrutto=' + new_brutto );
                            // console.log('newNetto=' + new_netto );
                            // console.log('newtax=' + new_tax );
                            // console.log('newDiscount=' + new_discount );
                            // console.log('newDiscountTax=' + new_discount_tax );
                        }
                        else
                        {
                            //Firmenkunde
                            //Ziehe den Rabatt vom Netto ab
                            new_netto = (netto_value / 100) * (100 - parseFloat(this.order.discount));
                            new_tax = (((new_netto / (100 )) * parseFloat(item.tax_rate)));
                            new_discount = netto_value - new_netto;
                            new_discount_tax = tax_value - new_tax;
                        }

                        this.order.total_discount += new_discount + new_discount_tax;
                    }



                    this.order.total_netto += netto_value + deposit_value + heavy_value + fragile_value - new_discount;
                    this.order.zwischensumme += netto_value + tax_value;

                    var tax = this.order.tax.find(i => i.type == item.tax_rate);
                    if(tax == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_tax = {
                            type: item.tax_rate,
                            amount: tax_value + deposit_tax + heavy_tax + fragile_tax - new_discount_tax,
                        };

                        this.order.tax.push(entry_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax.amount += tax_value + deposit_tax + heavy_tax + fragile_tax - new_discount_tax;
                    }
                }

                else if(item.type == 'deposit_sale')
                {
                    //Es handelt sich um einen Pfandverkauf. Also muss MwSt wieder nach Kundengruppe berechnet werden.
                    if(this.params.customer == "" || this.params.customer.group.tax == 1)
                    {
                        //Es ist ein Privatkunde
                        //Bei Privatkunden ist der Pfand inkl. MwSt. Also muss der Pfand umgerechnet werden nach Netto
                        deposit_value = ((item.price / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity;
                        deposit_tax = ((item.price / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate)) * item.quantity;
                    }

                    else
                    {
                        //Es ist ein Firmenkunde
                        //Bei Firmenkunden ist der Pfand zzgl Mwst. Pfand ist = Netto. Es muss nur dir anfallende Steuer berechnet werden
                        deposit_value = item.price * item.quantity;
                        deposit_tax = ((item.price / 100) * parseFloat(item.tax_rate)) * item.quantity;
                    }

                    this.order.total_netto += deposit_value;

                    //Addiere noch die Steuer zu dem Pfandverkauf. Suche dafür ob es den Steuersatz schon gibt. Wenn ja addieren sonst anlegen
                    var tax_deposit = this.order.tax.find(i => i.type == item.tax_rate);
                    if(tax_deposit == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_deposit_tax = {
                            type: item.tax_rate,
                            amount: deposit_tax,
                        };

                        this.order.tax.push(entry_deposit_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax_deposit.amount += deposit_tax;
                    }


                    //Addiere jetzt den Pfand zum GesamtPfand 
                    var string_type = item.deposit_type + '_' + item.tax_rate;

                    //Prüfe ob es schon ein Pfand Eintrag gibt hierzu.
                    var search = this.order.deposit.find(i => i.type == string_type);
                    if(search == undefined)
                    {
                        //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                        var entry_new = {
                            type: string_type,
                            tax: item.tax_rate,
                            amount: item.price * item.quantity
                        };

                        this.order.deposit.push(entry_new);
                    }
                    else
                    {
                        //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                        search.amount += item.price * item.quantity;
                    }
                }

                else{
                    return;
                }
            }, this);


            //Prüfe und Berechne hier noch den Kühlzuschlag
            if(this.hasCool() == true)
            {
                if(parseInt(this.params.shipping_address.zipcode) < 57000 || parseInt(this.params.shipping_address.zipcode) > 59999)
                {
                    var cool = 1;
                }

                if(cool == 1 && this.params.useCool == true)
                {
                    //Kühlzuschlag muss mit berechnet werden.
                    cool_value = (4 / 107) * 100;
                    cool_tax = (4 / 107) * 7;

                    this.order.total_netto += cool_value;
                    var tax_cool = this.order.tax.find(i => i.type == '7.00');
                    if(tax_cool == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_deposit_tax = {
                            type: '7.00',
                            amount: cool_tax,
                        };

                        this.order.tax.push(entry_deposit_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax_cool.amount += cool_tax;
                    }
                    this.order.total_cool = 4;
                }

                

            }

            
            


            //Berechne den Gesamtpfand der im Warenkorb liegt
            var pledge = 0;
            this.order.deposit.forEach(function(item) {
                pledge += item.amount;
            });

            this.order.total_pledge = pledge;

            //Berechne den Gesamtsperrgut der im Warenkorb liegt
            var heavy_data = 0;
            this.order.heavy.forEach(function(item) {
                heavy_data += item.amount;
            });

            this.order.total_cargo = heavy_data;


            //Berechne den Gesamtbruchgut der im Warenkorb liegt
            var fragile_data = 0;
            this.order.fragile.forEach(function(item) {
                fragile_data += item.amount;
            });

            this.order.total_fragile = fragile_data;



            //##################Berechne den Versand####################
            
            //Prüfe ob über 59Euro
            // var dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id); 

            if(this.order.zwischensumme < 69)
            {
                //Es fallsen also schonmal Versandkosten an
                //Prüfe nun ob die Versandkosten per Hand verboten wurden
                if(this.params.shippingFree == false)
                {
                    // Es müssen Versandkosten berechnet werden
                    // Ermittle zuerst den höchsten Steuersatz.
                    var shipping_tax_rate = '7.00';

                    if(this.order.tax.length > 0)
                    {
                        var rate = Math.max.apply(Math, this.order.tax.map(function(o) { return o.amount }))
                        shipping_tax_rate = this.order.tax.find(i => i.amount == rate).type;
                    }

                    //Berechne nun die Netto Versandkosten und die Steuer der Versandkosten

                    var shipping_value = (this.params.shippingCost / (100 + parseFloat(shipping_tax_rate))) * 100; //(4 / 119) * 100;
                    var shipping_tax = (this.params.shippingCost / (100 + parseFloat(shipping_tax_rate))) * parseFloat(shipping_tax_rate);

                    this.order.total_netto += shipping_value;
                    var tax_shipping = this.order.tax.find(i => i.type == shipping_tax_rate);
                    if(tax_shipping == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_shipping_tax = {
                            type: shipping_tax_rate,
                            amount: shipping_tax,
                        };

                        this.order.tax.push(entry_shipping_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax_shipping.amount += shipping_tax;
                    }
                    this.order.total_shipping = this.params.shippingCost;
                    this.order.total_shipping_net = shipping_value;
                    this.order.total_shipping_tax = shipping_tax_rate;

                }
                else{
                    this.order.total_shipping = 0;
                }
            }

            else{
                this.order.total_shipping = 0;
            }


            //################Ende Versand################################


            //Errechne nun den Gesamt Brutto aus dem Netto und den einzelnen Steuersätzen
            var brutto = this.order.total_netto;
            this.order.tax.forEach(function(item) {
                brutto += item.amount;
            }, brutto);

            this.order.total_brutto = brutto;


            
        },

        showArticleModal(){
            this.$bvModal.show("articleModal");
        },

        handleEditRow(index) {
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        fetchCustomers(search, loading)
        {
            loading(true);
            this.search = search;
            this.getCustomers();
            loading(false);
        },

        fetchArticles(search, loading)
        {
            loading(true);
            this.search = search;
            this.getArticles();
            loading(false);
        },

        getCustomers(page = 1){
            this.$Progress.start();
            this.axios
                .get("/customers?company_id=" + this.params.company_id + '&search=' + this.search + '&accountmode=0&page=' + page)
                .then((response) => {
                    this.customers = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getArticles(){
            this.$Progress.start();
            this.axios
                .get("/articles?search=" + this.search)
                .then((response) => {
                    this.articles = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getPayments(){
            this.$Progress.start();
            this.axios
                .get("/payments")
                .then((response) => {
                    this.payments = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getTaxes(){
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getDispatches(){
            this.$Progress.start();
            this.axios
                .get("/dispatches")
                .then((response) => {
                    this.dispatches = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getCompanies(){
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getStores(){
            this.$Progress.start();
            this.axios
                .get("/stores")
                .then((response) => {
                    this.stores = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

    getAbo(){
            this.$Progress.start();
            this.axios
                .get("/abos/" + this.$route.params.id)
                .then((response) => {
                    this.abo = response.data.data;
                        this.axios
                        .get("/customers/" + this.abo.customer_id)
                        .then((response) => {
                            var customer = response.data.data;
                            this.$Progress.finish();
                            this.params.customer = customer;
                            this.params.company_id = this.abo.company_id;
                            this.params.payment_id = this.abo.payment_id;
                            this.params.dispatch_id = this.abo.dispatch_id;
                            this.params.shippingCost = this.abo.shippingCost;
                            this.params.shippingFree = this.abo.shippingFree;
                            this.params.billing_id = this.abo.billing_address_id;
                            this.params.shipping_id = this.abo.shipping_address_id;
                            if(this.abo.billing_address_id == this.abo.shipping_address_id)
                            {
                                this.params.useBillingAddress == 'true';
                            }

                            else{
                                this.params.useBillingAddress = 'false';
                            }
                            //this.params.billing_id = this.abo.billing_address_id;
                            this.params.shipping_id = this.abo.shipping_address_id;
                            this.order.internalcomment = this.abo.internalcomment;
                            this.order.discount = this.abo.discount;
                            this.params.useCool = this.abo.useCool;
                            this.params.useCargo = this.abo.useCargo;
                            this.params.useFragile = this.abo.useFragile;
                            this.params.useDiscount = this.abo.useDiscount;
                            this.params.service = this.abo.service;
                            this.order.alternative = this.abo.alternative;
                            this.order.wishdate = this.abo.wishdate;
                            this.order.delivery_note = this.abo.delivery_note;
                            this.order.delivery_note_additional = this.abo.delivery_note_additional;
                            this.order.store_id = this.abo.store_id;
                            this.finalAbo.start_date = this.abo.start_date;
                            this.finalAbo.next_date = this.abo.next_date;
                            this.finalAbo.day = this.abo.day;
                            this.finalAbo.mode = this.abo.mode;
                            this.finalAbo.interval = this.abo.interval;
                            this.finalAbo.active = this.abo.active;
                            this.finalAbo.skip_till = this.abo.skip_till;
                            this.finalAbo.suspended_dates = this.abo.suspend;
                            this.params.articles = [];

                            this.abo.items.forEach(function(item) {


                            this.params.articles.push({
                                'articleordernumber': item.articleordernumber,
                                'name': item.name,
                                'quantity': item.quantity,
                                'price': item.price,
                                'tax_rate': item.tax_rate,
                                'article_id': item.article_id,
                                'article_detail_id': item.article_detail_id,
                                'type': item.type,
                                'deposit': item.deposit,
                                'deposit_type': item.deposit_type,
                                'cool': item.cool,
                                'fragile': item.fragile,
                                'heavy': item.heavy,
                                'discountable': item.discountable == 1 ? true : false,
                            });

                    },this)
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                            this.$Progress.fail();
                        });
                    

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

    },
    

    mounted() {
        this.getCompanies();
        this.getPayments();
        this.getDispatches();
        this.getTaxes();
        this.getStores(); 
        this.getAbo();
        //this.getCustomers();
    }

}
</script>